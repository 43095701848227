import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { translate } from '../../../../utils/translations';
import { homeInstPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import {initialHomeInstallationBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import Input from "../../../01_atoms/Input";
import TranslatedText from "../../../01_atoms/TranslatedText";
import getOfferType from "../../../../utils/getOfferType";

class HomeInstallation extends Component {
  constructor(props) {
    super(props);

    this.inputHandleChange = this.inputHandleChange.bind(this);
    this.checkboxHandleChange = this.checkboxHandleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  checkboxHandleChange(e, { value }) {
    const { dispatch, blockValue, block } = this.props;
    const newBlockValue = { ...blockValue };
    newBlockValue[value].checked = !newBlockValue[value].checked;
    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  inputHandleChange(e) {
    const { name, value } = e.target;
    const { dispatch, blockValue, block } = this.props;

    let inputValue = inputFilter(value);
    this.setState({[name]:inputValue});

    inputValue = inputValue=== '' ? 0 : inputValue;

    const newBlockValue = { ...blockValue };
    newBlockValue[name].value = inputValue;
    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, DDP_city, language, blockPriceValues, blockValue } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const id = option['machine_name'];

          if (getOfferType() === 'retail' && id === '3') {
            return
          }

          const { description } = option;
          const activeClass = blockPriceValues[id].checked ? 'active' : '';
          let label = translate(option.label, language);
          const price = blockPriceValues[id].price.toString();
          if (id === '2' || id === '3') {
            label += ` ${translate(DDP_city, language)}`;
          }
          let itemForRender = (
            <>
              <Checkbox
                label={label}
                name={block.machine_name}
                onChange={this.checkboxHandleChange}
                value={id}
                checked={blockPriceValues[id].checked}
              />
              {description && <Tip value={description} />}
            </>
          );

          switch (id) {
            case '3':
              let inputValue = blockValue[id].value;
              inputValue = +inputValue === 0 ? '' : inputValue;

              itemForRender = (
                <>
                  <Checkbox
                    label={label}
                    onChange={this.checkboxHandleChange}
                    value={id}
                    checked={blockPriceValues[id].checked}
                  />
                  {description && <Tip value={description} />}
                  <Input
                    label={<TranslatedText text='km'/>}
                    onChange={this.inputHandleChange}
                    name={id}
                    labelPosition="right"
                    disabled={!blockPriceValues[id].checked}
                    value={inputValue}
                    placeholder={0}
                  />
                </>
              );
              break;

            case '5':
              itemForRender = (
                <>
                  <span>{label}</span>
                  {description && <Tip value={description} />}
                </>
              );
              break;
          }

          return (
            <div className={`option-container ${activeClass}`} key={id}>
              <div className='option'>
                {itemForRender}
              </div>
              <div className='option-price'>
                { Price(price) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}


const mapStateToProps = ({ blocks, houseId, region, DDP_distance, DDP_city, language }, { block }) => {

  const blockValue = initialHomeInstallationBlock(block, blocks);

  const blockPriceValues = homeInstPriceCalculation(block, blockValue, { region, houseId, DDP_distance });

  return {
    DDP_city,
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(HomeInstallation);
