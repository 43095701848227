import React from 'react';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { connect } from 'react-redux';
import urlParse from 'url-parse';
import { Helmet } from 'react-helmet';
import PageLayout from '../components/04_templates/PageLayout';
import { changeCountry, changeLanguage, setActiveHouse } from '../store/actions';
import { translate } from '../utils/translations';
import './_styles.scss';
import getData from "../utils/getData";
import getOGLogo from "../utils/getOGLogo";

class App extends React.Component {

  componentDidMount() {
    const data = getData();
    const { dispatch } = this.props;
    const { configuration, houses } = data;
    const { countries } = configuration;
    const countryList = Object.keys(countries);

    // Define country by IP
    if (countryList.length > 1 &&
        typeof window !== "undefined"
    ) {
      fetch('https://ipapi.co/country/')
        .then(response => response.text())
        .then(countryCode => {
          countryCode = countryCode.toLowerCase();
          if (countryList.includes(countryCode)) {
            dispatch(changeCountry(countryCode));
          }
        });
    }

    const { environment, liveTrackingID, devTrackingID, hotjarID, hotjarSV } = configuration;

    // Google Analytics
    if (typeof window !== "undefined") {
      let trackingID;
      if (environment === 'dev' && devTrackingID) {
        trackingID = devTrackingID;
      } else if (environment === 'live' && liveTrackingID) {
        trackingID = liveTrackingID;
      } else {
        throw new Error('Environment variable not defined');
      }

      ReactGA.initialize(trackingID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // Hotjar
    if (environment === 'live' && hotjarID && hotjarSV) {
      hotjar.initialize(hotjarID, hotjarSV);
    }

    // URL parsing
    if (typeof window !== 'undefined') {
      const parsedUrl = urlParse(window.location.href, true);

      let countryCode = parsedUrl.query.country;
      if (countryCode) {
        const countries = Object.keys(configuration.countries);
        countryCode = countryCode.toLowerCase();
        if (countries.includes(countryCode)) {
          dispatch(changeCountry(countryCode));
        }
      }

      const langCode = parsedUrl.query.lang;
      if (langCode) {
        dispatch(changeLanguage(langCode));
      }

      let houseKey = parsedUrl.query.house;
      if (houseKey) {
        const houseModelList = Object.keys(houses);

        houseKey = houseKey.toLowerCase();
        if (houseModelList.includes(houseKey)){
          dispatch(setActiveHouse(houseKey));
        }
      }

      // Request to backend, when user visit calculator
      let leadId = parsedUrl.query.id;
      if (leadId && !isNaN(Number(leadId))) {
        const timeout = 2;
        setTimeout(() => {
          leadId = Number(leadId);
          const backendURL = 'https://amocrm-widget-backend.skit.by/';
          const secretKey = 'a7e2dfb32c1fbdafe1316aacaebbd707';
          const formData = new FormData();
          const body = {
            lead_id: leadId
          };
          formData.append("calc_visited", JSON.stringify(body));

          fetch(`${backendURL}?secret_key=${secretKey}`, {
            method: 'POST',
            body: formData,
            mode: "no-cors",
          });
        }, timeout * 1000);
      }
    }

  }

  render() {
    const { configuration } = getData();
    const { language, country } = this.props;
    const companyName = configuration['company_name'].toUpperCase();
    const siteDescription = translate('Prefabricated House Calculator', language);
    const companyDescription = translate('Manufacturer of wooden houses', language);
    const title = `${siteDescription} - ${companyName}`;
    let host = '';
    if (typeof window !== "undefined") {
      host = window.location.origin;
    }
    const logoPath = host + getOGLogo(country);

    return (
      <div className="body">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta property="og:title" content={`${companyName} - ${companyDescription}`}/>
          <meta property="og:description" content={siteDescription}/>
          <meta property="og:image" content={logoPath}/>
          <meta property="og:type" content="website" />
        </Helmet>

        <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap&subset=cyrillic" rel="stylesheet" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <PageLayout />
      </div>
    );
  }
}

const mapStateToProps = ({ language, country }) => ({
  language,
  country,
});

export default connect(mapStateToProps)(App);
