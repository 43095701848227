import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import {translate} from "../../../utils/translations";
import {connect} from "react-redux";

const Tip = ({ value, imageSource, language }) => {
  const translatedText = translate(value, language);
  return (
    <Popup
      trigger={<Icon style={{ marginLeft: '10px' }} name="info circle" />}
      wide
    >
      <Popup.Content>
        <div dangerouslySetInnerHTML={{__html: translatedText }} />
        {imageSource && <img src={imageSource} alt={translatedText} />}
      </Popup.Content>
    </Popup>
  );
};

const mapStateToProps = ({ language }) => ({ language });

export default connect(
  mapStateToProps,
)(Tip);
