export default function (country, language) {
  let url;

  switch (language) {
    case 'ru': {
      url = `/catalogs/CATALOG_RUS.pdf`;
      break;
    }

    case 'en': {
      url = `/catalogs/CATALOG_EN.pdf`;
      break;
    }

    case 'de': {
      url = `/catalogs/CATALOG_DE.pdf`;
      break;
    }

    default:
      url = `/catalogs/CATALOG_EN.pdf`;
  }

  switch (country) {
    case 'spb':
    case 'msk':
    case 'krd': {
      url = `/catalogs/CATALOG_RUSSIA.pdf`;
      break;
    }

    case 'by': {
      url = `/catalogs/CATALOG_BELARUS.pdf`;
      break;
    }
  }

  return url;
}