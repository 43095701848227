import getData from "./getData";

export default function () {
  const { configuration } = getData();

  if (!configuration.hasOwnProperty('currency')) {
    throw new Error("Data has not 'currency' field")
  }

  const currency = configuration['currency'].toUpperCase();

  switch (currency) {
    case 'USD': return '$';

    case 'EUR': return '€';

    case 'RUB': return '₽';

    default: throw new Error(`Unknown currency: ${currency}`);
  }
}