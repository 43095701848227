export const inputFilter = (inputValue, filterType) => {
  let result;

  switch (filterType) {
    case 'negative_numbers': {
      let filter = /^-?/;
      let minus = inputValue.match(filter);
      minus = minus === null ? '' : minus[0];

      filter = /[1-9][0-9]*[.,]?[0-9]*/;
      let number = inputValue.match(filter);
      number = number === null ? '' : number[0];

      result = minus + number;
      break;
    }

    default: {
      const filter = /[1-9][0-9]*[.,]?[0-9]*/;
      result = filter.exec(inputValue);
      result = result === null ? '' : result[0];
    }
  }

  if (result === null) {
    return '';
  }

  return result.replace(",",".");
};