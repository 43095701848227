import React from 'react';
import {Grid} from 'semantic-ui-react';
import Configuration from '../../03_organisms/Summary';
import BlocksList from '../../03_organisms/BlocksList';
import Header from '../../03_organisms/Header';
import './_styless.scss'
import HouseView from "../../03_organisms/HouseView";

const PageLayout = () => {

  return (
    <Grid columns={1} stackable>
      <Grid.Column id='header-column'>
        <Header/>
      </Grid.Column>

      <Grid.Column>
        <Grid columns={2} stackable centered>
          <Grid.Column width={11}>
            <HouseView slidesToShow={1}
                       className='mobileHouseView'
            />
            <HouseView slidesToShow={3}
                       className='desktopHouseView'
            />
            <BlocksList/>
          </Grid.Column>

          <Grid.Column width={4}>
            <Configuration/>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  )
};

export default PageLayout;
