import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateAreaValue, updateBlockValue } from '../../../../store/actions';
import TranslatedText from '../../../01_atoms/TranslatedText';
import divideNumber from '../../../../utils/divideNumber';
import { terracePriceCalculation } from '../../../../utils/blocksPriceCalculation';
import {initialTerraceBlock} from "../../../../utils/initialBlock";
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";
import {inputFilter} from "../../../../utils/inputFilter";

class Terrace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      0: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
    dispatch(updateAreaValue(block.machine_name, blockValue[0].value));
  }

  handleChange(e) {
    const { value, name } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);
    this.setState({[name]:inputValue});

    inputValue = inputValue === '' ? 0 : inputValue;

    const newBlockValue = { ...blockValue };
    newBlockValue[name].value = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
    dispatch(updateAreaValue(block.machine_name, newBlockValue[0].value));
  }

  render() {
    const { block, blockPriceValues } = this.props;
    const option = block.values.options[0];
    const { price } = blockPriceValues[0];
    const unitPrice = blockPriceValues[0].unit_price;
    const activeClass = Number(price) === 0 ? '' : 'active';
    const inputValue = this.state[0];

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        <div className={`option-container ${activeClass}`} key={option.label}>
          <div className='option'>
            <TranslatedText text={option.label} />
            <Input
              label={`x ${divideNumber(unitPrice)} ${getCurrencySign()}`}
              name={0}
              onChange={this.handleChange}
              labelPosition="right"
              placeholder="0"
              value={inputValue}
            />

            {option.description && <Tip value={option.description} />}
          </div>

          <div className='option-price'>
            { Price(price.toString()) }
          </div>

        </div>
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region }, { block }) => {

  const blockValue = initialTerraceBlock(block, blocks);

  const blockPriceValues = terracePriceCalculation(block, blockValue, { region, houseId });

  return {
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(Terrace);
