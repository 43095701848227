import {Rail, Sticky} from "semantic-ui-react";
import { connect } from 'react-redux';
import TranslatedText from "../../01_atoms/TranslatedText";
import React, { Component } from "react";
import './_styles.scss'
import divideNumber from "../../../utils/divideNumber";
import getCurrencySign from "../../../utils/getCurrencySign";

class MobileSticky extends  Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: ''
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(){
    const scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    const windowHeight = Math.max(
      document.body.clientHeight, document.documentElement.clientHeight
    );
    const scrollOffset = window.pageYOffset;
    if (scrollHeight - scrollOffset - windowHeight < 355) {
      this.setState({hidden: 'hidden'});
    } else {
      this.setState({hidden: ''});
    }
  }

  handleClick(){
    const windowHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
    window.scrollTo(0,windowHeight);
  }

  render(){
    return (
      <Rail attached
            internal
            position='left'
            className={`mobile-rail ${this.state.hidden}`}
      >
        <Sticky context={this.props.contextRef}
                bottomOffset={50}
        >
          <div className='mobile-price'>
            <div className='sticky-price-container'>
              <span className='total-price'><TranslatedText text='Total price'/></span>
              <span className='price-value'>{getCurrencySign()}{divideNumber(this.props.price, ' ')}</span>
            </div>
            <div className='button'
                  onClick={this.handleClick}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.49 31.49">
                <path d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"/>
              </svg>
            </div>
          </div>
        </Sticky>
      </Rail>
    )
  }
}


const mapStateToProps = ({ houseId }) => ({
  houseId
});

export default connect(mapStateToProps)(MobileSticky);
