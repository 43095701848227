import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Checkbox} from 'semantic-ui-react';
import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import Input from "../../../01_atoms/Input";
import BlockContainer from '../../BlockContainer';
import {deleteBlockValue, updateBlockValue} from '../../../../store/actions';
import TranslatedText from '../../../01_atoms/TranslatedText';
import divideNumber from '../../../../utils/divideNumber';
import {inputCheckboxesPriceCalculation} from '../../../../utils/blocksPriceCalculation';
import {initialInputCheckboxesBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import getCurrencySign from "../../../../utils/getCurrencySign";
import { translate } from '../../../../utils/translations';

class InputCheckboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      let value = Number(blockPriceValues[key].value);
      value = value === 0 ? '' : value;
      this.state[key] = value;
    });

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange(e, dataCheckbox) {
    const { block, dispatch, blockValue } = this.props;
    const newBlockValue = { ...blockValue };

    const option = block.values.options.find(option => (option.machine_name === e.target.name));
    const optionType = option.type;
    
    switch (option.type) {
      case 'input': {
        const { value, id } = e.target;
        let inputValue = inputFilter(value);
        this.setState({[id]:inputValue});
        inputValue = inputValue === '' ? 0 : inputValue;
        newBlockValue[id].value = inputValue;
        break;
      }
      case 'checkbox': {
        newBlockValue[dataCheckbox.value].checked = !newBlockValue[dataCheckbox.value].checked;
        break;
      }
    }
    
    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, language, blockPriceValues } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option, index) => {
          const priceValue = blockPriceValues[index].price;
          const unitPrice = blockPriceValues[index].unit_price;
          const inputValue = this.state[index];
          let activeClass;

          let itemForRender;

          switch (option.type) {
            case 'input': {
              activeClass = Number(priceValue) === 0 ? '' : 'active';
              itemForRender = (
                <>
                  <TranslatedText text={option.label} />
                  <Input
                    id={index}
                    label={`x ${divideNumber(unitPrice)} ${getCurrencySign()}`}
                    onChange={this.handleChange}
                    name={option.machine_name}
                    labelPosition="right"
                    placeholder="0"
                    value={inputValue}
                  />
                </>
              );
              break;
            }

            case 'checkbox': {
              activeClass = blockPriceValues[index].checked ? 'active' : '';
              itemForRender = (
                <Checkbox
                  id={block.machine_name + option.machine_name}
                  label={translate(option.label, language)}
                  name={option.machine_name}
                  onChange={this.handleChange}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  value={index}
                  checked={blockPriceValues[index].checked}
                  disabled={blockPriceValues[index].price === ''}
                />
              );
              break;
            }
          }

          return (
            <div className={`option-container ${activeClass}`} key={option.label}>
              <div className='option'>
                {itemForRender}
                {option.description && <Tip value={option.description} />}
              </div>

              <div className='option-price'>
                { Price(priceValue.toString()) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region }, { block }) => {

  const blockValue = initialInputCheckboxesBlock(block, blocks);

  const blockPriceValues = inputCheckboxesPriceCalculation(block, blockValue, { houseId, region });

  return {
    block,
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(InputCheckboxes);