import getData from "./getData";

export const getHousePrice = (houseId, country) => {
  const data = getData();

  const houseData = data.houses[houseId];

  if (typeof houseData.price !== "object"){
    return Number(houseData.price);
  }

  return houseData.price[country];
};
