import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../utils/translations';

const TranslatedText = ({ text, language }) => (
  translate(text, language)
);

const mapStateToProps = ({ language }) => ({ language });

export default connect(
  mapStateToProps,
)(TranslatedText);
