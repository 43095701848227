import React from 'react';
import divideNumber from '../../../utils/divideNumber';
import TranslatedText from '../TranslatedText';
import getCurrencySign from "../../../utils/getCurrencySign";

export default function Price(value) {
  if (!isNaN(Number(value))){
    return (
      <span>
        {getCurrencySign()} {divideNumber(value)}
      </span>
    );
  }
  if (value === 'absent') {
    return
  }
  if (value === 'base') {
    return (
      <TranslatedText text="Basic option" />
    );
  }
  if (value === '') {
    return (
      <TranslatedText text="Не доступно" />
    );
  }
  if (value.includes('h')) {
    const hours = value.slice(0, value.indexOf('h'));
    return (
      <span>
        {hours} <TranslatedText text="hours" />
      </span>
    );
  }
  if (value.includes('d')) {
    const days = value.slice(0, value.indexOf('h'));
    return (
      <span>
        {days} <TranslatedText text="days" />
      </span>
    );
  }
  if (value.includes('c')) {
    const cars = value.slice(0, value.indexOf('c'));
    return (
      <span>
        {cars} <TranslatedText text="trucks" />
      </span>
    );
  }

  return <span>{value}</span>
}
