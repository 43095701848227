import React from 'react';
import App from '../app/App';

export default () => (
  <>
    <link
      rel="stylesheet"
      href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
    />
    <App />
  </>
);
