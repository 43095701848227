import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import TranslatedText from '../../../01_atoms/TranslatedText';
import divideNumber from '../../../../utils/divideNumber';
import {addBathroomPriceCalculation} from '../../../../utils/blocksPriceCalculation';
import {initialAddBathroomBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";

class AdditionalBathroom extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      let value = Number(blockPriceValues[key].value);
      value = value === 0 ? '' : value;
      this.state[key] = value;
    });

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange(e) {
    const { value, name } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);
    this.setState({[name]:inputValue});

    inputValue = inputValue === '' ? 0 : inputValue;
    const newBlockValue = { ...blockValue };
    newBlockValue[name].value = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, blockPriceValues } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option, index) => {
          const priceValue = blockPriceValues[index].price;
          const unitPrice = blockPriceValues[index].unit_price;
          const inputValue = this.state[index];
          const activeClass = Number(priceValue) === 0 ? '' : 'active';

          return (
            <div className={`option-container ${activeClass}`} key={option.label}>
              <div className='option'>
                <TranslatedText text={option.label} />
                <Input
                  label={`x ${divideNumber(unitPrice)} ${getCurrencySign()}`}
                  onChange={this.handleChange}
                  name={index}
                  labelPosition="right"
                  placeholder="0"
                  value={inputValue}
                />

                {option.description && <Tip value={option.description} />}

              </div>

              <div className='option-price'>
                { Price(priceValue.toString()) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region }, { block }) => {

  const blockValue = initialAddBathroomBlock(block, blocks);

  const blockPriceValues = addBathroomPriceCalculation(block, blockValue, { region, houseId, blocks });

  return {
    block,
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(AdditionalBathroom);
