export default function (country) {
  const path = '/images/og_logos/';
  let image = 'eu_logo.png';

  switch (country) {
    case 'by':
      image = 'by_logo.png';
      break;

    case 'spb':
    case 'msk':
    case 'krd':
      image = 'ru_logo.png';
      break;
  }

  return path + image;
}