import React, { Component } from 'react';
import { Item } from 'semantic-ui-react';
import TranslatedText from '../../01_atoms/TranslatedText';
import Tip from '../../01_atoms/Tip';
import './_styles.scss'

class BlockContainer extends Component {
  render() {
    return (
      <Item>
        <Item.Image
          size="small"
          src={this.props.image}
        />

        <Item.Content>
          <Item.Header id='header'>
            <TranslatedText text={this.props.header} />
            {this.props.tooltip && <Tip value={this.props.tooltip} />}
          </Item.Header>

          <Item.Description>
            {this.props.children}
          </Item.Description>

          {this.props.description && (
            <Item.Extra>
              <TranslatedText text={this.props.description} />
            </Item.Extra>
          )}
        </Item.Content>
      </Item>
    );
  }
}

export default BlockContainer;
