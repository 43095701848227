import React from "react";
import './_styles.scss'

class Input extends React.Component {
  constructor(props) {
    super(props);

    let minSize = 3, maxSize = 5;

    if (typeof props.size !== "undefined") {
      minSize = props.size;
      maxSize = props.size;
    } else {
      if (typeof props.minSize !== "undefined") {
        minSize = props.minSize;
      }

      if (typeof props.maxSize !== "undefined") {
        maxSize = props.maxSize;
      }
    }

    this.state = {
      minSize: minSize,
      maxSize: maxSize,
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.getInputSize = this.getInputSize.bind(this);
  }

  getInputSize(value) {
    const valueLen = value.length;

    const { minSize, maxSize } = this.state;

    if (minSize <= valueLen && valueLen <= maxSize) {
      return valueLen;
    } else if (valueLen > maxSize) {
      return maxSize;
    } else {
      return minSize;
    }
  }

  handleChange(e) {
    this.setState({value: e.target.value});

    const onChange = this.props.onChange;
    if (typeof onChange !== "undefined") {
      onChange(e);
    }
  }

  render() {
    const props = Object.assign({}, this.props);
    delete props.onChange;

    //Fluid input
    const fluidClass = props.fluid ? 'fluid' : '';
    delete props.fluid;

    //Label position
    let labelPos = 'right', label;
    if (props.hasOwnProperty('labelPosition')) {
      labelPos = props.labelPosition;

      delete props.labelPosition;
    }

    //Label
    if (typeof props.label !== "undefined") {
      label = <div className={`label ${labelPos}`}>
                {props.label}
              </div>;

      delete props.label;
    }

    //Dynamic input size
    const value = this.state.value && props.value;
    const currentSize = this.getInputSize(value);

    //Style prop
    const style = props.style;
    delete props.style;

    //Text align
    let textAlignClass = props.textAlign === 'left' ? 'text-align-left' : '';
    delete props.textAlign;

    return (
      <div
        className={`custom-input ${fluidClass}`}
        style={style}
      >
        {
          label && labelPos === 'left' ?
            label : null
        }
        <input
          style={{}}
          type='text'
          className={`input ${fluidClass} ${textAlignClass}`}
          size={currentSize}
          onChange={this.handleChange}
          {...props}
        />
        {
          label && labelPos === 'right' ?
            label : null
        }
      </div>
    )
  }
}

export default Input