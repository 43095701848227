export default function (block, optionIndex) {
  let blockImage;
  const options = block.values.options;

  if (typeof optionIndex !== "undefined"){
    if (typeof options[optionIndex] !== "undefined"){
      blockImage = options[optionIndex].image;
    }
    return blockImage;
  }

  if (block.image === ""){

    //  Find checked block image
    const checkedOption = options.find(item => (item['checked']));

    if ( typeof checkedOption !== "undefined" &&
      checkedOption.hasOwnProperty('image')) {

      blockImage = checkedOption.image;
    } else {
      const optionWithImage = options.find(item => (item.hasOwnProperty('image')));
      blockImage = optionWithImage.image;
    }

  } else {
    blockImage = block.image;
  }

  return blockImage;
}