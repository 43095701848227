import React, { Component } from 'react';
import { connect } from 'react-redux';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import Tip from '../../../01_atoms/Tip';
import { translate } from '../../../../utils/translations';
import { deliveryPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import {initialDeliveryBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import Input from "../../../01_atoms/Input";
import TranslatedText from "../../../01_atoms/TranslatedText";

class Delivery extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      if (blockPriceValues[key].hasOwnProperty('value')) {
        let value = Number(blockPriceValues[key].value);
        value = value === 0 ? '' : value;
        this.state[key] = value;
      }
    });

    this.inputHandleChange = this.inputHandleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  inputHandleChange(e) {
    const { name, value } = e.target;
    const { dispatch, blockValue, block, blocks } = this.props;

    let inputValue = inputFilter(value);
    this.setState({[name]:inputValue});

    inputValue = inputValue === '' ? 0 : inputValue;
    const newBlockValue = { ...blockValue };
    newBlockValue[name].value = inputValue;
    dispatch(updateBlockValue(block.machine_name, newBlockValue));

    // Change Foundation values
    const foundationBlockName = 'foundation';
    if (!blocks.hasOwnProperty(foundationBlockName)) {
      throw new Error(`"${foundationBlockName}" block not found`)
    } else {
      const foundationBlockValue = blocks[foundationBlockName];
      if (foundationBlockValue.hasOwnProperty(3)) {
        foundationBlockValue[3].value = inputValue;
      }
      if (foundationBlockValue.hasOwnProperty(5)) {
        foundationBlockValue[5].value = inputValue;
      }

      dispatch(updateBlockValue(foundationBlockName, foundationBlockValue));
    }

    // Change Home installation values
    const homeInstallBlockName = 'home_installation';
    if (!blocks.hasOwnProperty(homeInstallBlockName)) {
      throw new Error(`"${homeInstallBlockName}" block not found`)
    } else {
      const homeInstallBlockValue = blocks[homeInstallBlockName];
      if (homeInstallBlockValue.hasOwnProperty(3)){
        homeInstallBlockValue[3].value = inputValue;
      }

      dispatch(updateBlockValue(homeInstallBlockName, homeInstallBlockValue));
    }
  }

  render() {
    const { block, DDP_city, language, blockPriceValues } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const id = option['machine_name'];
          let activeClass = '';
          if (blockPriceValues[id].checked) {
            if (blockPriceValues[id].hasOwnProperty('value')) {
              const price = Number(blockPriceValues[id].price);
              if (price === 0) {
                activeClass = '';
              } else {
                activeClass = 'active';
              }
            } else {
              activeClass = 'active';
            }
          }
          const { description } = option;
          let label = translate(option.label, language);
          const price = blockPriceValues[id].price.toString();
          if (id === '0' || id === '1') {
            label += ` ${translate(DDP_city, language)}`;
          }
          let itemForRender;

          switch (id) {
            case '1':
              const inputValue = this.state[id];
              itemForRender = (
                <>
                  {label}
                  {description && <Tip value={description} />}
                  <Input
                    label={<TranslatedText text='km'/>}
                    onChange={this.inputHandleChange}
                    name={id}
                    labelPosition="right"
                    disabled={!blockPriceValues[id].checked}
                    value={inputValue}
                    placeholder={0}
                  />
                </>
              );
              break;

            default:
              itemForRender = (
                <>
                  <span>{label}</span>
                  {description && <Tip value={description} />}
                </>
              );
          }
          
          return (
            <div className={`option-container ${activeClass}`} key={id}>
              <div className='option'>
                {itemForRender}
              </div>
              <div className='option-price'>
                { Price(price) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, DDP_distance, DDP_city, language, country }, { block }) => {

  const blockValue = initialDeliveryBlock(block, blocks);

  const blockPriceValues = deliveryPriceCalculation(block, blockValue, { region, houseId, DDP_distance, blocks, country });

  return {
    blocks,
    DDP_city,
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(Delivery);
