import React, {Component} from 'react';
import {Card, Grid, Icon, Image, Menu, Segment} from 'semantic-ui-react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import './_styles.scss';
import {setActiveHouse} from "../../../store/actions";
import getData from "../../../utils/getData";
import {getHousePrice} from "../../../utils/getHousePrice";
import Price from "../../01_atoms/Price";
import TranslatedText from "../../01_atoms/TranslatedText";
import {translate} from "../../../utils/translations";
import urlParse from "url-parse";
import getCatalogLink from "../../../utils/getCatalogLink";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className={className}
         style={style}
         onClick={onClick}
         viewBox="0 0 477.175 477.175">
      <path d="M360.731 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5
              215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3
               9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"
      />
    </svg>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 477.175 477.175"
         className={className}
         style={style}
         onClick={onClick}
    >
      <path d="M145.188 238.575l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1
                 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5
                 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1l-215.4-215.5z"
      />
    </svg>
  )
}

class HouseView extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.handleHouseClick = this.handleHouseClick.bind(this);
  }

  componentDidMount() {
    const data = getData();
    const { houses } = data;
    let { houseId } = this.props;

    // URL parsing
    if (typeof window !== 'undefined') {
      const parsedUrl = urlParse(window.location.href, true);
      let houseKey = parsedUrl.query.house;

      if (houseKey) {
        houseKey = houseKey.toLowerCase();
        const houseModelList = Object.keys(houses);
        if (houseModelList.includes(houseKey)){
          houseId = houseKey;
        }
      }
    }

    const houseModel = houses[houseId].model;
    const tabName = houseModel.toUpperCase();

    const housesLineup = [];
    Object.keys(houses).forEach( houseId => {
      const house = houses[houseId];
      if (house.model === houseModel) {
        housesLineup.push(houseId);
      }
    });

    const initialSlide = housesLineup.indexOf(houseId);

    this.setState({
      initialSlide: initialSlide,
      activeTab: tabName,
    });
  }

  getHouseCards(activeModel, activeHouse) {
    const data = getData();
    const { houses } = data;
    const result = [];
    const { language } = this.props;

    Object.keys(houses).forEach(houseKey => {
      const houseData = houses[houseKey];
      if (houseData.model.toUpperCase() !== activeModel) {
        return;
      }

      let activeClass = '';
      if (activeHouse === houseKey) {
        activeClass = 'active-card';
      }

      result.push (
        <Card as={'div'}
              onClick={() => this.handleHouseClick(houseKey)}
              key={houseData.name}
              className={activeClass}
        >
          <Card.Content textAlign="center">
            <Card.Header>
              <span className="house-model">{houseData.name}</span>
              {/*<span className='house-price'>{Price(getHousePrice(houseKey, this.props.country))}</span>*/}
            </Card.Header>
            <Image src={houseData.image} className="house-image"/>
            <Grid
              columns={3}
              verticalAlign="bottom"
              className="house-data"
            >
              <Grid.Column className="house-info-column">
                <div className="info-header">
                  <TranslatedText text="Building area" />
                </div>
                <div className="info-value">
                  {`${houseData.area} ${translate('m²', language)}`}
                </div>
              </Grid.Column>
              <Grid.Column className="house-info-column">
                <div className="info-header">
                  <TranslatedText text="Useful area" />
                </div>
                <div className="info_value">
                  {`${houseData.useful_area} ${translate('m²', language)}`}
                </div>
              </Grid.Column>
              <Grid.Column className="house-info-column">
                <div className="info-header">
                  <TranslatedText text="Veranda area" />
                </div>
                <div className="info-value">
                  {`${houseData.veranda_area} ${translate('m²', language)}`}
                </div>
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
      );
    });
    return result;
  }

  getMenuTabs(housesData, activeTab, onClick){
    const models = [];

    for (const key in housesData) {
      if (housesData.hasOwnProperty(key)) {
        const house = housesData[key];
        const model = house['model'].toUpperCase();

        if (!models.includes(model)) {
          models.push(model);
        }
      }
    }

    return models.map(model => (
      <Menu.Item
        name={model}
        active={activeTab === model}
        onClick={onClick}
      />
    ));
  }

  handleItemClick = (e, { name }) => this.setState({ activeTab: name });

  handleHouseClick = (houseId) => {
    const { dispatch } = this.props;

    dispatch(setActiveHouse(houseId));
  };

  render() {
    const { houses } = getData();
    const { slidesToShow, className, houseId, language, country } = this.props;
    const { activeTab, initialSlide } = this.state;

    const sliderItems = this.getHouseCards(activeTab, houseId);

    const catalogLink = getCatalogLink(country, language);

    const menuTabs = this.getMenuTabs(houses, activeTab, this.handleItemClick);

    const settings = {
      dots: false,
      infinite: sliderItems.length > 3,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      initialSlide: initialSlide,
      centerMode: true,
      focusOnSelect: true,
      swipeToSlide: true,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>,
    };

    return (
      <div className={`house-view ${className}`}>
        <Menu attached="top" tabular>
          {menuTabs}

          <a href={catalogLink} target='_blank' className='catalog-link'>
            <Icon name='download'/>
            <span>{translate('Download catalog', language)}</span>
          </a>
        </Menu>

        <Segment attached="bottom">
          <Slider {...settings}>
            {sliderItems}
          </Slider>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = ({ houseId, country, language }) => {

  return {
    language,
    houseId,
    country,
  }
};

export default connect(mapStateToProps)(HouseView);
