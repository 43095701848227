export default function divideNumber(value, divider = '\'') {
  let number = parseFloat(value);
  if (isNaN(number)) {
    return value;
  }
  const numeric = Math.round(number);
  let sign = '';
  number = numeric.toString();

  if (numeric < 0) {
    number = number.slice(1);
    sign = '-';
  }


  const { length } = number;
  for (let i = length - 3; i > 0; i -= 3) {
    number = `${number.slice(0, i)}${divider}${number.slice(i)}`;
  }

  number = sign + number;
  return number;
}
