import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import RegionSelection from '../../02_molecules/RegionSelection';
import './_styles.scss';
import getData from "../../../utils/getData";
import TranslatedText from "../../01_atoms/TranslatedText";

class Header extends Component {
  render() {
    const data = getData();
    const { offer_type, logo_src, logo_link } = data.configuration;

    let messageStyle = {
      backgroundColor:  '#ff6161',
      color:            'white',
      fontSize:         '16px',
      textAlign:        'center',
      padding:          '10px 24px',
      lineHeight:       '1',
      fontWeight:       '400',
      margin:           '0 -14px',
    };

    let messageText =
      'Due to the rise in prices for building materials, prices are recalculated. ' +
      'Prices will be updated shortly.';

    return (
      <div className="site-header">
        {/*<div className="message" style={messageStyle}>*/}
        {/*  <TranslatedText text={messageText}/>*/}
        {/*</div>*/}
        <div className='content-wrap'>
          <div className='header-logo'>
            <a
              href={ logo_link }
              target='_blank'
            >
              <Image src={ logo_src } className='logo'/>
            </a>
            <span className='calculator-type'><TranslatedText text={offer_type}/></span>
          </div>
          <div style={{ height: '30px' }} />
          <RegionSelection />
        </div>
      </div>
    );
  }
}

export default Header;
