/**
 * Set active house.
 */
export const setActiveHouse = (houseId) => ({
  type: 'SET_ACTIVE_HOUSE',
  houseId,
});

/**
 * Updates block values.
 */
export const updateBlockValue = (blockId, value) => ({
  type: 'UPDATE_BLOCK_VALUE',
  blockId,
  value,
});

/**
 * Change country action.
 */
export const changeCountry = (countryCode) => ({
  type: 'CHANGE_COUNTRY',
  country: countryCode,
});

/**
 * Change site language.
 */
export const changeLanguage = (langCode) => ({
  type: 'CHANGE_LANGUAGE',
  language: langCode,
});

/**
 * Updates area values.
 */
export const updateAreaValue = (optionName, value) => ({
  type: 'UPDATE_AREA_VALUE',
  optionName,
  value,
});

/**
 * Delete block value.
 */
export const deleteBlockValue = (blockId) => ({
  type: 'DELETE_BLOCK_VALUE',
  blockId,
});

/**
 * Update store.
 */
export const updateStore = () => ({
  type: 'UPDATE_STORE',
});
