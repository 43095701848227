import React, { Component } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import urlParse from 'url-parse';
import { changeLanguage, changeCountry } from '../../../store/actions';
import './_styles.scss';
import getData from "../../../utils/getData";
import {translate} from "../../../utils/translations";

class RegionSelection extends Component {
  constructor(props) {
    super(props);

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleCountryChange = (e, { value }) => {
    const { dispatch } = this.props;

    dispatch(changeCountry(value));
  };

  handleLanguageChange = (e, { value }) => {
    const { dispatch } = this.props;

    dispatch(changeLanguage(value));
  };

  render() {
    const { configuration } = getData();
    const countries = configuration['countries'];

    const { language, country } = this.props;
    let countryParamExist = false;
    let langParamExist = false;

    let countryDisable = false;
    let languageDisable = false;

    if (typeof window !== 'undefined') {
      const parsedUrl = urlParse(window.location.href, true);
      countryParamExist = parsedUrl.query.country;
      langParamExist = parsedUrl.query.lang;
    }

    // Disable country and language selection
    if (country === 'by' && language === 'ru') {
      countryDisable = true;
      languageDisable = true;
    }

    if (country === 'spb' ||
        country === 'msk' ||
        country === 'krd' &&
        language === 'ru') {
      languageDisable = true;
    }

    if (country === 'us') {
      countryDisable = true;
      languageDisable = true;
    }

    const countryList = Object.keys(countries).map(countryKey => {
      const countryName = countries[countryKey].name;
      return { key: countryKey, value: countryKey, flag: countryKey, text: translate(countryName, language) }
    });

    countryList.sort( (a, b) => {
      const text1 = a.text;
      const text2 = b.text;

      if (text1 > text2)   return 1;
      if (text1 === text2) return 0;
      if (text1 < text2)   return -1;
    });

    // Set "Not selected" country to first place
    let notSelectedIndex = countryList.findIndex(item => (
      item.key === 'empty'
    ));

    if (notSelectedIndex !== -1) {
      let notSelectedItem = countryList.splice(notSelectedIndex, 1)[0];
      countryList.unshift(notSelectedItem);
    }

    const languageList = [
      { key: 'en', value: 'en', flag: 'gb', text: 'English' },
      { key: 'ru', value: 'ru', flag: 'ru', text: 'Русский' },
      { key: 'de', value: 'de', flag: 'de', text: 'Deutsche' },
      { key: 'lt', value: 'lt', flag: 'lt', text: 'Lietuvis' },
      { key: 'pl', value: 'pl', flag: 'pl', text: 'Polskie' },
      { key: 'se', value: 'se', flag: 'se', text: 'Svenska' },
    ];

    return (
      <div className="region-selection">
        {
          !langParamExist
          && (
            <div className="selection">
              <Icon name="world" id="icon" />
              <Dropdown
                className='language'
                id="dropdown"
                compact
                direction="left"
                options={languageList}
                defaultValue={language}
                onChange={this.handleLanguageChange}
                disabled={languageDisable}
              />
            </div>
          )
        }
        {
          !countryParamExist
          && (
            <div className="selection">
              <Icon name="map marker alternate" id="icon" />
              <Dropdown
                className='country'
                id="dropdown"
                compact
                direction="left"
                options={countryList}
                value={country}
                onChange={this.handleCountryChange}
                disabled={countryDisable}
              />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({ language, country }) => ({
  language,
  country,
});

export default connect(mapStateToProps)(RegionSelection);
