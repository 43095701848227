import React, { Component } from 'react';
import { connect } from 'react-redux';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import TranslatedText from '../../../01_atoms/TranslatedText';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { inputPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import {initialEquipmentRentalBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";

class EquipmentRental extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      let value = Number(blockPriceValues[key].value);
      value = value === 0 ? '' : value;
      this.state[key] = value;
    });

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange(e) {
    const { value } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);
    this.setState({0 :inputValue});

    inputValue = inputValue === '' ? 0 : inputValue;
    const newBlockValue = { ...blockValue };
    newBlockValue[0].value = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, blockPriceValues } = this.props;

    const inputValue = this.state[0];
    const hours = blockPriceValues[0].unit_price;
    const { price } = blockPriceValues[0];
    const { label } = block.values.options[0];
    const activeClass = Number(price) === 0 ? '' : 'active';
    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        <div className={`option-container ${activeClass}`}>
          <div className='option'>
            <TranslatedText text={label} />
            <Input
              style={{ marginRight: '10px' }}
              label={`${getCurrencySign()}`}
              onChange={this.handleChange}
              value={inputValue}
              placeholder="0"
              labelPosition="right"
            />
            x
            <Input
              style={{ marginLeft: '10px' }}
              label={<TranslatedText text='hours'/>}
              labelPosition="right"
              value={hours}
              disabled
            />
          </div>
          <div className='option-price'>
            {Price(price.toString())}
          </div>
        </div>
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region }, { block }) => {

  let blockValue = initialEquipmentRentalBlock(block, blocks);

  const blockPriceValues = inputPriceCalculation(block, blockValue, { region, houseId });

  return {
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(EquipmentRental);
