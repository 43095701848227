import getData from "./getData";
import getCalculatedBlock from "./getCalculatedBlock";
import { roundToPrecision } from "./roundToPrecision";
import { getHousePrice } from "./getHousePrice";

export default function (store) {
  const { houseId, blocks, country } = store;
  const data = getData();
  const totalDiscountPercentage = data.configuration['total_discount_percentage'] || 0;

  const totalBlocksPrice = Object.keys(blocks).reduce((totalPrice, machine_name) => {
    const currentBlock = blocks[machine_name];
    const blockData = data.blocks.find((block) => block.machine_name === machine_name);

    const calculatedBlock = getCalculatedBlock(blockData, currentBlock, store);

    if (!calculatedBlock.hasOwnProperty('totalPrice')) {
      throw new Error(`Calculated block ${machine_name} has not a totalPrice field`);
    }

    const blockPrice = Number(calculatedBlock.totalPrice);

    return totalPrice + blockPrice;
  }, 0);

  const vatPercentage = data.configuration['VAT'][country];
  if (typeof vatPercentage === "undefined") {
    throw new Error(`Country "${country}" does not have VAT`);
  }
  const housePrice = Number(getHousePrice(houseId, country));

  const totalPriceWithoutDiscount = housePrice + totalBlocksPrice;
  const totalPrice = totalDiscountPercentage !== 0
    ? roundToPrecision(totalPriceWithoutDiscount - (totalPriceWithoutDiscount / 100) * totalDiscountPercentage, 10)
    : totalPriceWithoutDiscount;
  const vatPrice = (totalPrice * vatPercentage) / 100;
  const totalPriceWithVAT = vatPrice + totalPrice;

  return {
    totalPriceWithoutDiscount: totalPriceWithoutDiscount,
    totalPrice: totalPrice,
    vatPrice: vatPrice,
    totalPriceWithVAT: totalPriceWithVAT,
    totalDiscountTillDate: data.configuration['total_discount_till_date'] || null,
  }
}

